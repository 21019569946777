import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keyname:'',
    payMoney:'',
    payMorningMoney:'',
    userId:'',
    val:{}
  },
  getters: {
  },
  mutations: {
    changeKeyName(state,keyname){
      state.keyname = keyname;
    },
    changePayMoney(state,payMoney){
      state.payMoney = payMoney
    },
    changePayMorningMoney(state,payMorningMoney){
      state.payMorningMoney = payMorningMoney
    },
    changeUserId(state,userId){
      state.userId = userId
    },
    changeVal(state,val){
      state.val={...val}
    },
    reset(state){
      state.keyname=''
      state.payMoney=''
      state.userId=''
      state.val = {}
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[
    createPersistedState({
        reducer(val){ // 注意: 指定需要持久化的数据
            return {
              payMoney:val.payMoney,
              payMorningMoney:val.payMorningMoney
            }
        }
    })
]

})
