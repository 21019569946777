<template>
  <span>
    <keep-alive :include="cashViews">
      <router-view v-if="$route.meta.iskeepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.iskeepAlive" />
  </span>
</template>
<script>
export default {
  data () {
    return {
      // 要缓存的组件
      cashViews: ['digitalCliceCenter', 'morningSliceCenter']
    }
  }
}
</script>

<style lang="less">
#app {
  background-color: rgb(245, 245, 245);
  height: 100vh;
}
html {
  background-color: rgb(245, 245, 245);
  height: 100vh;
}
.noticeWidth{
  min-width: 100px !important;
}
</style>
