import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant'
import ElementUI from 'element-ui'
import 'vant/lib/index.css'
import 'vant/lib/icon/local.css'
import 'element-ui/lib/theme-chalk/index.css'
import './init.js'
import './assets/icons' // icon
// import api from '@/request/http.js' // 导入api接口

// Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.use(Vant)
Vue.use(ElementUI)

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // 这句是重点， 'render-event' 需要和 vue.config.js里的renderAfterDocumentEvent值一致
     document.dispatchEvent(new Event("render-event"));
   }
}).$mount('#app')
