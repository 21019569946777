import Vue from 'vue'
import VueRouter from 'vue-router'
// import homeView from '../views/home/mobile.vue'

Vue.use(VueRouter)
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej)
  }
  else {
    originPush.call(this, location, () => { }, () => { })
  }

}
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej)
  }
  else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

// 默认路由
export const routes = [

  // {
  //   path: '/',
  //   redirect: '/mobile'
  // },

  {
    path: '/register',
    component: () => import('@/views/register'),
  },

  {
    path: '/reset',
    component: () => import('@/views/reset.vue'),
  },
  {
    path: '/pay',
    component: () => import('@/views/pay'),
  },
  {
    path: '/',
    name: 'mobile',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/mobile.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/pc',
    name: 'pc',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/pc.vue')
  },
  {
    path: '/digitalCliceCenter',
    name: 'digitalCliceCenter',
    component: () => import('../views/digitalClice/index.vue'),
    meta: {
      title: '数字切片',
      iskeepAlive: true
    },
  },
  {
    path: '/digitalViewDetail',
    name: 'digitalViewDetail',
    component: () => import('../views/digitalClice/digitalDetail/index.vue'),
    meta: {
      title: '数字切片详情',
      iskeepAlive: true
    }
  },
  {
    path: '/morningSliceCenter',
    name: 'morningSliceCenter',
    component: () => import('../views/morningSlice/index.vue'),
    meta: {
      title: '晨读切片',
      iskeepAlive: true
    }
  },
  {
    path: '/morningSliceDetail',
    name: 'morningSliceDetail',
    component: () => import('../views/morningSlice/morningDetail/index.vue'),
    meta: {
      title: '晨读切片详情',
      iskeepAlive: true
    }
  }, {
    path: '*',
    name: 'error',
    component: import(/* webpackChunkName: "about" */ '../views/home/mobile.vue'),
    redirect: '/'
  }
]
// // pc端的路由
// export const pcRoutes = [
//   // {
//   //   path: '/',
//   //   redirect: '/pc'
//   // },
//   {
//     path: '/pc',
//     name: 'pc',
//     component: () =>
//       import(/* webpackChunkName: "about" */ '../views/home/pc.vue')
//   },
//   {
//     path: '/digitalCliceCenter',
//     name: 'digitalCliceCenter',
//     component: () => import('../views/digitalClice/index.vue'),
//     meta: {
//       title: '数字切片',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/digitalViewDetail',
//     name: 'digitalViewDetail',
//     component: () => import('../views/digitalClice/digitalDetail/index.vue'),
//     meta: {
//       title: '数字切片详情',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/morningSliceCenter',
//     name: 'morningSliceCenter',
//     component: () => import('../views/morningSlice/index.vue'),
//     meta: {
//       title: '晨读切片',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/morningSliceDetail',
//     name: 'morningSliceDetail',
//     component: () => import('../views/morningSlice/morningDetail/index.vue'),
//     meta: {
//       title: '晨读切片详情',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '*',
//     name: 'error',
//     component: import(/* webpackChunkName: "about" */ '../views/home/pc.vue'),
//     redirect: '/pc'
//   }
// ]
// // 移动端设备路由
// export const mobileRoutes = [
//   {
//     path: '/',
//     redirect: '/mobile'
//   },
//   {
//     path: '/mobile',
//     name: 'mobile',
//     component: () =>
//       import(/* webpackChunkName: "about" */ '../views/home/mobile.vue')
//   },
//   {
//     path: '/pc',
//     name: 'pc',
//     component: () =>
//       import(/* webpackChunkName: "about" */ '../views/home/pc.vue')
//   },
//   {
//     path: '/digitalCliceCenter',
//     name: 'digitalCliceCenter',
//     component: () => import('../views/digitalClice/index.vue'),
//     meta: {
//       title: '数字切片',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/digitalViewDetail',
//     name: 'digitalViewDetail',
//     component: () => import('../views/digitalClice/digitalDetail/index.vue'),
//     meta: {
//       title: '数字切片详情',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/morningSliceCenter',
//     name: 'morningSliceCenter',
//     component: () => import('../views/morningSlice/index.vue'),
//     meta: {
//       title: '晨读切片',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '/morningSliceDetail',
//     name: 'morningSliceDetail',
//     component: () => import('../views/morningSlice/morningDetail/index.vue'),
//     meta: {
//       title: '晨读切片详情',
//       iskeepAlive: true
//     }
//   },
//   {
//     path: '*',
//     name: 'error',
//     component: import(/* webpackChunkName: "about" */ '../views/home/mobile.vue'),
//     redirect: '/'
//   }
// ]

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: 'history',
    routes: routes
  })

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
